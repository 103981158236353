import { Outlet, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("userIndex") ? true : false;

  useEffect(() => {
    if (isLoggedIn) {
      axios.get("/api/content/membership2").then((res) => {
        if (res.data.status === 2) {
          alert(res.data.message);
          navigate("/shop", { replace: true });
        } else if (res.data.status === 5) {
          alert(res.data.message);
          navigate("/shop", { replace: true });
        } else if (res.data.status === 6) {
          alert(res.data.message);
          navigate("/shop", { replace: true });
        } else return;
      });
    }
  }, [isLoggedIn, navigate]);

  return isLoggedIn ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/study/content/home" replace />
  );
};

export default PrivateRoutes;
